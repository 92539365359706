
import { computed, defineComponent, reactive } from "vue";
import { useStore } from "vuex";
import { useRoute, useRouter } from "vue-router";
import { useHead } from "@vueuse/head";

import CModal from "@/components/Modal.vue";
import CFormInputText from "@/components/form/InputText.vue";
import CFormTextarea from "@/components/form/Textarea.vue";

import { BatchForm } from "@/store/batch/types";
import { formatDate } from "@/libs/utils";

const VBatchForm = defineComponent({
  name: "VBatchForm",
  components: { CModal, CFormInputText, CFormTextarea },
  setup() {
    const store = useStore();
    const route = useRoute();
    const router = useRouter();

    useHead({ title: "Cadastrar lote | obmed" });

    const selectedGuides = computed(() => store.state.batch.selectedGuides);
    const form = reactive<BatchForm>({
      cd_estabelecimento: Number(selectedGuides.value[0]?.cd_estabelecimento),
      cd_guia_eletronica_item: selectedGuides.value.map((guide) => Number(guide.cd_guia_eletronica_id)),
      dt_final: route.query.dt_final?.toString() || "",
      dt_inicial: route.query.dt_inicial?.toString() || "",
      ds_observacao: "",
    });
    const loading = reactive({ submit: false });

    function handleClose() {
      router.back();
    }

    async function onSubmit() {
      loading.submit = true;
      const response = await store.dispatch("createBatch", { form });
      loading.submit = false;

      if ((response?.status || 500) < 300) {
        store.commit("addToast", { severity: "success", summary: "Lote de pagamento cadastrado com sucesso!" });
        handleClose();
      }
    }

    if (!selectedGuides.value.length) handleClose();

    return {
      ...{ form, loading },
      ...{ handleClose, onSubmit, formatDate },
    };
  },
});

export default VBatchForm;
